import React from 'react';

import classNames from 'classnames/bind';
import style from './Row.module.scss';
const cx = classNames.bind(style);

export type RowProps = {
    /**
     * Vertical alignment of children
     * @default "center"
     */
    align?: 'start' | 'center' | 'end' | 'stretch';

    /**
     * Horizontal justification of children
     * @default "start"
     */
    justify?: 'start' | 'center' | 'end' | 'around' | 'between' | 'evenly';

    /**
     * Spacing between each column in the row
     */
    gap?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

    /**
     * Grow columns to fill available space
     * @default false
     */
    grow?: boolean;

    /**
     * Additional CSS class names
     */
    className?: string;
};

/**
 * Simple utility component that displays children in a horizontal row
 */
export const Row: React.FC<RowProps> = (props) => {
    const { align, justify, gap, grow, className, children } = props;

    return (
        <div
            className={cx(
                'row',
                `is-align-${align || 'center'}`,
                `is-justify-${justify || 'start'}`,
                gap ? `has-gap-${gap}` : null,
                {
                    'is-grow': grow,
                },
                className
            )}
        >
            {children}
        </div>
    );
};

export default Row;
