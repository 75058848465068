import React from 'react';

import classNames from 'classnames/bind';
import style from './Container.module.scss';
const cx = classNames.bind(style);

export type ContainerProps = {
    className?: string;
    wide?: boolean;
};

export const Container: React.FC<ContainerProps> = (props) => {
    const { className, wide, children } = props;

    return <div className={cx('container', { 'is-wide': wide }, className)}>{children}</div>;
};

export default Container;
