import React from 'react';

import classNames from 'classnames/bind';
import style from './Grid.module.scss';
const cx = classNames.bind(style);

export type GridProps = {
    className?: string;
};

export const Grid: React.FC<GridProps> = (props) => {
    const { className, children } = props;

    return <div className={cx('grid', className)}>{children}</div>;
};

export default Grid;
