import { Link } from 'gatsby';
import React, { useCallback } from 'react';

import { AnalyticsEvent, trackEvent } from '../analytics';

import classNames from 'classnames/bind';
import style from './Button.module.scss';
const cx = classNames.bind(style);

export type ButtonProps = {
    align?: 'left' | 'center' | 'right';
    analyticEvent?: AnalyticsEvent;
    className?: string;
    color?: 'blue' | 'dark' | 'green' | 'light' | 'orange' | 'purple' | 'red' | 'white' | 'yellow' | 'rainbow';
    disabled?: boolean;
    href?: string;
    preserveSearchParams?: boolean;
    inverseColor?: 'blue' | 'dark' | 'green' | 'light' | 'orange' | 'purple' | 'red' | 'white' | 'yellow';
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    target?: '_blank' | '_self';
    to?: string;
    wide?: boolean;
};

export const Button: React.FC<ButtonProps> = (props) => {
    const {
        align = 'center',
        analyticEvent,
        children,
        className,
        disabled,
        href,
        preserveSearchParams = true, // merge in query params e.g. for analytics
        inverseColor,
        onClick,
        target,
        to,
        color,
        wide,
    } = props;

    const wrapperClassNames = cx('wrapper', { [`is-${align}`]: align });

    const onNavigate = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (analyticEvent) {
                trackEvent(analyticEvent);
            }

            onClick?.(event);
        },
        [analyticEvent]
    );

    const classNames = cx(
        'btn',
        color && `is-${color}`,
        inverseColor && `is-${inverseColor}-inverse`,
        { 'is-wide': wide },
        className
    );

    const mergeSearchParams = (href: string, newSearchParams: string) => {
        if (!('URLSearchParams' in window) || /^#/.test(href)) {
            return href;
        }

        // Parse the original href
        const parsedHref = new URL(href);

        // Extract the params from the search strings
        const sp = new URLSearchParams(newSearchParams);
        const hp = new URLSearchParams(parsedHref.search);

        // Merge the search queries
        const mergedParams = new URLSearchParams({
            ...Object.fromEntries(sp),
            ...Object.fromEntries(hp),
        }).toString();

        // Update the search of the original href
        parsedHref.search = mergedParams;

        return parsedHref.toString();
    };

    const renderButton = () => {
        if (to) {
            return (
                <div className={wrapperClassNames}>
                    <Link className={classNames} onClick={onNavigate} to={to}>
                        {children}
                    </Link>
                </div>
            );
        } else if (href) {
            const isUrl = href ? /^(?:#|mailto:|(?:https?:))/i.exec(href) !== null : false;
            if (isUrl) {
                const isSSR = typeof window === 'undefined';

                // NOTE: we have to do it this more complicated way instead of
                // only overwriting href b/c the client gets confused and
                // doesn't rewrite due to some sort of gatsby SSR thing
                if (isSSR) {
                    return <div className={wrapperClassNames}>{children}</div>;
                } else {
                    const mergedHref = preserveSearchParams ? mergeSearchParams(href, window.location.search) : href;
                    return (
                        <div className={wrapperClassNames}>
                            <a className={classNames} onClick={onNavigate} href={mergedHref} target={target}>
                                {children}
                            </a>
                        </div>
                    );
                }
            } else {
                return (
                    <div className={wrapperClassNames}>
                        <Link className={classNames} onClick={onNavigate} to={href}>
                            {children}
                        </Link>
                    </div>
                );
            }
        }

        return (
            <button className={classNames} onClick={onClick} disabled={disabled}>
                {children}
            </button>
        );
    };

    return renderButton();
};

export default Button;
