import React from 'react';

import { applyShortcode } from '../utility/shortcodes';
import { color } from '../config/shortcodes';

import classNames from 'classnames/bind';
import style from './Headline.module.scss';
const cx = classNames.bind(style);

export type HeadlineProps = {
    children?: string;
    className?: string;
};

export const Headline: React.FC<HeadlineProps> = (props) => {
    const { className, children } = props;

    return <h1 className={cx('headline', className)}>{applyShortcode(color, children)}</h1>;
};

export default Headline;
