import { Link } from 'gatsby';
import React, { useState } from 'react';

import Button from './Button';
import FeaturesContext from '../contexts/FeaturesContext';

import ArrowDown from '../images/icons/arrow-down.svg';

import classNames from 'classnames/bind';
import style from './Menu.module.scss';
const cx = classNames.bind(style);

export const Menu: React.FC = () => {
    const [expanded, setExpanded] = useState(false);

    const toggleMenu = () => {
        setExpanded(!expanded);
    };

    return (
        <nav className={`${cx('menu')}${expanded ? ' is-expanded' : ''}`} role="navigation">
            <Button className={cx('menu-btn', { 'is-close-btn': expanded })} onClick={toggleMenu}>
                <span className="sr-only">Toggle Menu</span>
                <div className={cx('line1')}></div>
                <div className={cx('line2')}></div>
            </Button>
            <ul className={cx('menu-links', { 'is-expanded': expanded })}>
                <li className={cx('has-drop-menu')}>
                    <Link className={cx('menu-link')} to="/product" onClick={toggleMenu}>
                        Product <ArrowDown className={cx('menu-item-arrow')} />
                    </Link>
                    <FeaturesContext.Consumer>
                        {(features) => (
                            <ul className={cx('drop-menu')}>
                                {features &&
                                    features.map((feature) => (
                                        <li key={`fmi-${feature.sys.id}`}>
                                            <Link
                                                className={cx('menu-link')}
                                                to={`/product#${feature.fields.slug}`}
                                                onClick={toggleMenu}
                                            >
                                                {feature.fields.title}
                                            </Link>
                                        </li>
                                    ))}
                            </ul>
                        )}
                    </FeaturesContext.Consumer>
                </li>
                <li>
                    <a className={cx('menu-link')} href="https://facet.space" onClick={toggleMenu}>
                        Community
                    </a>
                </li>
                <li>
                    <a className={cx('menu-link')} href="https://blog.facet.ai" onClick={toggleMenu}>
                        Blog
                    </a>
                </li>
                <li>
                    <Link className={cx('menu-link')} to="/about" onClick={toggleMenu}>
                        About
                    </Link>
                </li>
                <li>
                    <a className={cx('menu-link')} href="https://help.facet.ai" onClick={toggleMenu}>
                        Help
                    </a>
                </li>
                <li>
                    <Link className={cx('menu-link')} to="/jobs" onClick={toggleMenu}>
                        Jobs
                    </Link>
                </li>
                <li>
                    <a className={cx('menu-link')} href="https://app.facet.ai/login" onClick={toggleMenu}>
                        Sign In
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Menu;
