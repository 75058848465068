import React from 'react';

export type PageContextState = {
    inverseHeader?: boolean;
    isOnePage?: boolean;
};

export const PageContext = React.createContext<PageContextState>({
    inverseHeader: false,
    isOnePage: false,
});
export default PageContext;
