import React from 'react';

import FacetLogo from '../images/facet-logo.svg';
import FacetWordmark from '../images/facet-wordmark.svg';

import classNames from 'classnames/bind';
import style from './Logo.module.scss';
const cx = classNames.bind(style);

export type LogoProps = {
    className?: string;
    size?: 'sm' | 'md' | 'lg';
    wordmark?: boolean;
};

export const Logo: React.FC<LogoProps> = (props) => {
    const { size, wordmark, className } = props;

    return (
        <div className={cx('logo', `is-${size || 'md'}`, className)}>
            <FacetLogo className={cx('mark')} />
            {wordmark && <FacetWordmark className={cx('wordmark')} />}
        </div>
    );
};

export default Logo;
