import { Link } from 'gatsby';
import React from 'react';

import { AnalyticsEvent } from '../analytics';
import PageContext from '../contexts/PageContext';
import Button from './Button';
import Container from './Container';
import Logo from './Logo';
import Menu from './Menu';
import Row from './Row';

import classNames from 'classnames/bind';
import style from './Header.module.scss';
const cx = classNames.bind(style);

export type HeaderProps = {
    hasInverseHeader?: boolean;
};

export const Header: React.FC<HeaderProps> = (props) => {
    const { hasInverseHeader } = props;

    return (
        <PageContext.Consumer>
            {({ inverseHeader }) => (
                <header className={cx('header', { inverse: hasInverseHeader || inverseHeader })} role="banner">
                    {/* Leaving this for future use */}
                    {/* <a href="https://app.facet.ai/pricing">
                        <div className={cx('announcement')}>
                            <Container className={cx('announcement-container')}>
                                <div className={cx('announcement-content-md')}>
                                    ⚫ Instant access to new features now available. Get started
                                    <span className={cx('chevron')}> ›</span>
                                </div>
                                <div className={cx('announcement-content-sm')}>
                                    ⚫ Get Instant Access<span className={cx('chevron')}> ›</span>
                                </div>
                            </Container>
                        </div>
                    </a> */}
                    <Container className={cx('header-container')}>
                        <Row className={cx('header-row')} align="center" justify="between">
                            <Link className={cx('logo')} to="/">
                                <Logo size="sm" wordmark={true} />
                                <span className="sr-only">Facet</span>
                            </Link>
                            <Menu />
                            <Button
                                className={cx('signup')}
                                analyticEvent={AnalyticsEvent.Signup}
                                href="https://app.facet.ai/pricing"
                                color="blue"
                                inverseColor="light"
                            >
                                Try for free
                            </Button>
                        </Row>
                    </Container>
                </header>
            )}
        </PageContext.Consumer>
    );
};

export default Header;
