import React from 'react';

export type Shortcode = {
    split: RegExp;
    match: RegExp;
    replace: (matches: string[]) => React.ReactNode;
};

// [color:{color}]...[/color]
export const color: Shortcode = {
    split: /\[\/color\]/gi,
    match: /(.*?)\[color:(.*?)\](.*)$/gi,
    replace: (matches) => (
        <>
            {matches[1]}
            <span className={`color-${matches[2]}`}>{matches[3]}</span>
        </>
    ),
};

// [table]...[/table]
export const table: Shortcode = {
    split: /\[\/table\]/gi,
    match: /(.*?)\[table\](.*)$/gi,
    replace: (matches) => (
        <>
            {matches[1]}
            <table>
                {matches[2].split(/\[\/tr\]/gi).map((row, i) => (
                    <tr key={i}>
                        {row
                            .replace(/\[tr\]/gi, '')
                            .split(/\[\/td\]/gi)
                            .map((cell, j) => (
                                <td key={j}>{cell.replace(/\[td\]/gi, '')}</td>
                            ))}
                    </tr>
                ))}
            </table>
        </>
    ),
};
