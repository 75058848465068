import React from 'react';

import { Shortcode } from '../config/shortcodes';

export const applyShortcode = (shortcode: Shortcode, content?: string): React.ReactNode => {
    if (!content) {
        return null;
    }
    const transformed: React.ReactNode[] = [];

    const segments = content.split(shortcode.split);
    for (const segment of segments) {
        let segmentMatch = shortcode.match.exec(segment);
        if (!segmentMatch) {
            transformed.push(<>{segment}</>);
        }
        while (segmentMatch) {
            transformed.push(shortcode.replace(segmentMatch));
            segmentMatch = shortcode.match.exec(segment);
        }
    }

    return (
        <>
            {transformed.map((segment, i) => (
                <React.Fragment key={i}>{segment}</React.Fragment>
            ))}
        </>
    );
};
