import React from 'react';

import Footer from './Footer';
import Header from './Header';
import PageContext from '../contexts/PageContext';

import classNames from 'classnames/bind';
import style from './Layout.module.scss';
const cx = classNames.bind(style);

export type LayoutProps = {
    hasInverseHeader?: boolean;
    hasStickyContent?: boolean;
    preMainContent?: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = (props) => {
    const { children, hasStickyContent, hasInverseHeader, preMainContent } = props;

    return (
        <PageContext.Consumer>
            {({ inverseHeader, isOnePage }) => (
                <div className={cx('facet', { 'one-page': isOnePage })}>
                    <Header hasInverseHeader={hasInverseHeader} />
                    {preMainContent}
                    <main
                        className={cx('main', {
                            'has-inverse-header': hasInverseHeader || inverseHeader,
                            'has-sticky': hasStickyContent,
                        })}
                        role="main"
                    >
                        {children}
                    </main>
                    <Footer />
                </div>
            )}
        </PageContext.Consumer>
    );
};

export default Layout;
