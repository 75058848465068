export enum AnalyticsEvent {
    Signup = 'User / Signup CTA Interaction',
    SignupWithEmail = 'User / Signup Form Submitted',
}

export function trackEvent<M extends Record<string, unknown>>(name: AnalyticsEvent, meta?: M) {
    if (!window.analytics) {
        console.warn('Analytics tracking not available');
    }

    window.analytics.track(name, meta);
}
