import { Link } from 'gatsby';
import React from 'react';

import Container from './Container';
import Grid from './Grid';
import Logo from './Logo';
import Button from './Button';
import { AnalyticsEvent } from '../analytics';

// import Facebook from '../images/icons/facebook.svg';
import Instagram from '../images/icons/instagram.svg';
import Medium from '../images/icons/medium.svg';
import Twitter from '../images/icons/twitter.svg';

import classNames from 'classnames/bind';
import style from './Footer.module.scss';
const cx = classNames.bind(style);

export const Footer: React.FC = () => {
    return (
        <footer className={cx('colophon', 'inverse')} role="contentinfo">
            <Container>
                <Grid>
                    <div className={cx('about')}>
                        <Logo size="lg" className={cx('footer-logo')} />
                        <nav className={cx('utility')} role="navigation">
                            <ul className={cx('utility-links')}>
                                <li>
                                    <a href="https://blog.facet.ai">Blog</a>
                                </li>
                                <li>
                                    <a href="https://facet.space">Community</a>
                                </li>
                                <li>
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li>
                                    <a href="https://help.facet.ai">Help</a>
                                </li>
                                <li>
                                    <Link to="/jobs">Jobs</Link>
                                </li>
                                <li>
                                    <a href="https://app.facet.ai/pricing">Pricing</a>
                                </li>
                                <li>
                                    <a href="mailto:hi@facet.ai">Contact</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className={cx('connect')}>
                        <p className={cx('footer-lead')}>No downloads, nothing to install. Try Facet free today!</p>
                        <Button
                            className={cx('footer-signup')}
                            analyticEvent={AnalyticsEvent.Signup}
                            href="https://app.facet.ai/pricing"
                            color="blue"
                            inverseColor="light"
                            align="left"
                        >
                            🔥Get started
                        </Button>
                    </div>
                    <div className={cx('legal')}>
                        <span className={cx('copyright')}>&copy; Facet, {currentYear()}</span>
                        <span className={cx('bullet')}>•</span> <Link to="/privacy">Privacy Policy</Link>
                        <span className={cx('bullet')}>•</span>
                        <Link to="/terms-of-use">Terms of Use</Link>
                    </div>
                    <div className={cx('social')}>
                        <ul className={cx('social-links')}>
                            <li>
                                <a href="https://twitter.com/facet_ai">
                                    <Twitter />
                                    <span className="sr-only">Follow Facet on Twitter</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://medium.com/facet-ai">
                                    <Medium />
                                    <span className="sr-only">Read Facet on Medium</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/facet.app/">
                                    <Instagram />
                                    <span className="sr-only">Follow Facet on Instagram</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </Grid>
            </Container>
        </footer>
    );
};

export default Footer;

const currentYear = (): string => {
    return new Date().getFullYear().toString();
};
